<template>
  <div v-if="!$isMobile">
    <div>
      <div class="false_head_con">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-card title="" :bordered="false">
              <a-row :gutter="16">
                <a-col :span="6">
                  <div class="greyCon gc01">
                    <a-statistic title="本月签单总数" :value="focusData ? focusData.monthNumber : 0">
                      <template #suffix>
                        单
                      </template>
                    </a-statistic>
                  </div>
                </a-col>
                <a-col :span="6">
                  <div class="greyCon gc02">
                    <a-statistic title="本月签单总额" :value="focusData ? focusData.monthAmount : 0">
                      <template #suffix>
                        万元
                      </template>
                    </a-statistic>
                  </div>
                </a-col>
                <a-col :span="6">
                  <div class="greyCon gc03">
                    <a-statistic title="本周签单数" :value="focusData ? focusData.weekNumber : 0">
                      <template #suffix>
                        单
                      </template>
                    </a-statistic>
                  </div>
                </a-col>
                <a-col :span="6">
                  <div class="greyCon gc02" style="cursor: pointer;">
                    <a-statistic title="本周签单金额" :value="focusData ? focusData.weekAmount : 0">
                      <template #suffix>
                        万元
                      </template>
                    </a-statistic>
                  </div>
                </a-col>
              </a-row>
            </a-card>
          </a-col>
        </a-row>
      </div>
      <div class="false_bottom">
        <table-page :config="config" />
      </div>
    </div>
  </div>
  <div v-else class="m-home">
    <div>
      <a-row :gutter="{ xs: 8, sm: 16, md: 12, lg: 12 }">
        <a-col :span="24">
          <a-card title="" class="m-r_lfcon false_head_con">
            <div class="greyCon" style="background-color: #EFF7FF;">
              <a-statistic title="录入客资(条)" :value="focusData ? focusData.monthNumber : 0">
                <template #suffix>
                  条
                </template>
              </a-statistic>

            </div>
            <div class="greyCon" style="background-color: #FFF4F5;">
              <a-statistic title="已分配客资(条)" :value="focusData ? focusData.monthAmount : 0">
                <template #suffix>
                  条
                </template>
              </a-statistic>
            </div>
            <div class="greyCon" style="background-color: #EFF7FF;">
              <a-statistic title="分配藤德客资(条)" :value="focusData ? focusData.weekNumber : 0">
                <template #suffix>
                  条
                </template>
              </a-statistic>
            </div>
            <div class="greyCon" style="background-color: #FFF4F5;">
              <a-statistic title="藤德精准客资(条)" :value="focusData ? focusData.weekAmount : 0">
                <template #suffix>
                  条
                </template>
              </a-statistic>
            </div>
          </a-card>
        </a-col>
      </a-row>
      <div class="false_bottom">
        <a-card title="" :bordered="false">
          <table-page :config="config" />
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import chainIndex from "@/components/home/chainIndex";
import TablePage from "@/components/TablePage";
import dayjs from "dayjs";
export default {
  name: "Home",
  components: {
    chainIndex,
    TablePage
  },
  data() {
    return {
      config: {
        dataUrl: `/report/signed/order/list`,
        setPageSize: 100,
        filter: {
          controls: [
            {
              key: 'time',
              label: '时间',
              type: 'dateRange',
              resetDisabled: true,
              config: {
                initialValue: '当月',
              }
            },
          ]
        },
        columns: [
          {
            key: 'signingTime',
            title: '签单日期',
            width: '130px',
            type: 'textHtml',
            config: {
              text: (val) => {
                if (val.orgName.indexOf('藤德留学') > -1) {
                  return `<span style="font-weight: bold;">${dayjs(val.signingTime).format('YYYY-MM-DD')}</span>`
                } else {
                  return dayjs(val.signingTime).format('YYYY-MM-DD')
                }
              }
            }
          },
          {
            key: 'orderAmount',
            title: '签单金额',
            width: '130px',
            type: 'textHtml',
            config: {
              text: (val) => {
                if (val.orgName.indexOf('藤德留学') > -1) {
                  return `<span style="font-weight: bold;">${val.orderAmount}</span>`
                } else {
                  return val.orderAmount
                }
              }
            }
          },
          {
            key: 'customerAttributionName',
            title: '客资归属人',
            width: '130px',
            type: 'textHtml',
            config: {
              text: (val) => {
                if (val.customerAttributionName) {
                  if (val.orgName.indexOf('藤德留学') > -1) {
                    return `<span style="font-weight: bold;">${val.customerAttributionName}</span>`
                  } else {
                    return val.customerAttributionName
                  }
                } else {
                  return ''
                }
              }
            }
          },
          {
            key: 'customerSource',
            title: '客户来源',
            width: '130px',
            type: 'textHtml',
            config: {
              text: (val) => {
                if (val.customerSource) {
                  if (val.orgName.indexOf('藤德留学') > -1) {
                    return `<span style="font-weight: bold;">${val.customerSource}</span>`
                  } else {
                    return val.customerSource
                  }
                } else {
                  return ''
                }
              }
            }
          },
          {
            key: 'staffName',
            title: '客服',
            width: '130px',
            type: 'textHtml',
            config: {
              text: (val) => {
                if (val.staffName) {
                  if (val.orgName.indexOf('藤德留学') > -1) {
                    return `<span style="font-weight: bold;">${val.staffName}</span>`
                  } else {
                    return val.staffName
                  }
                } else {
                  return ''
                }
              }
            }
          },
          {
            key: 'orderAttributionName',
            title: '业务归属人',
            width: '130px',
            type: 'textHtml',
            config: {
              text: (val) => {
                if (val.orderAttributionName) {
                  if (val.orgName.indexOf('藤德留学') > -1) {
                    return `<span style="font-weight: bold;">${val.orderAttributionName}</span>`
                  } else {
                    return val.orderAttributionName
                  }
                } else {
                  return ''
                }
              }
            }
          },
          {
            key: 'orderFollowUpName',
            title: '跟进人',
            width: '130px',
            type: 'textHtml',
            config: {
              text: (val) => {
                if (val.orderFollowUpName) {
                  if (val.orgName.indexOf('藤德留学') > -1) {
                    return `<span style="font-weight: bold;">${val.orderFollowUpName}</span>`
                  } else {
                    return val.orderFollowUpName
                  }
                } else {
                  return ''
                }
              }
            }
          },
          {
            key: 'orgName',
            title: '签单机构',
            width: '130px',
            type: 'textHtml',
            config: {
              text: (val) => {
                if (val.orgName.indexOf('藤德留学') > -1) {
                  return `<span style="font-weight: bold;">${val.orgName}</span>`
                } else {
                  return val.orgName
                }
              }
            }
          },
        ],
        /* 表格操作 */
        actions: [
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
        ]
      },
      focusData: {},

    }
  },
  async mounted() {
    this.getStatistics()
  },

  methods: {
    async getStatistics() {
      try {
        let res = await this.$http.get('/report/signed/order/statistics');
        if (res.code == '200') {
          this.focusData = res.data;
        }
      } catch ({ message }) {
        this.$message.error(message)
      }
    },
  }
}
</script>

<style scoped lang="scss">
.false_head_con {
  .greyCon {
    height: 100px;
  }
}

.false_bottom {
  margin-top: 40px;
}

.greyCon {
  background-color: #f1f1f1;
  padding: 15px;
  min-width: 130px;
}

:deep(.r_lfcon .ant-card-body) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .greyCon {
    width: 22%;
    margin: 0 10px;
    min-width: 180px;
  }
}

:deep(.ant-statistic-content-prefix) {
  font-size: 14px;
}

:deep(.ant-statistic-content-suffix) {
  font-size: 14px;
}

:deep(.ant-table-wrapper .ant-table-thead >tr>th) {
  background: none;
}

:deep(.ant-card-body) {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px; // 横向滚动条
    height: 6px; // 纵向滚动条 必写
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 3px;
  }
}

:deep(.m-r_lfcon .ant-card-body) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .greyCon {
    width: 100%;
    margin-bottom: 10px;
  }
}

.m-home {
  :deep(.ant-card-body) {
    padding: 10px;
  }

  :deep(.ant-card-head) {
    padding: 6px;
  }
}

:deep(.ant-table-thead > tr >th) {
  background: #E8EDFF !important;
}
</style>
<style lang="scss">
.greyCon.gc01 {
  background: #e1f4ff !important;
}

.greyCon.gc02 {
  background: #fff4f5 !important;
}

.greyCon.gc03 {
  background: #e9f8ff !important;
}
</style>