<template>
    <p class="item">
        <span v-if="LastData > 0 && ((CurrentData - LastData) / LastData * 100).toFixed(2) > 0" style="color: #FD5D64;">
            <span style="margin-right: 4px; color: #FD5D64;">{{ title ? '' : '环比' }}</span>
            <UpCircleTwoTone two-tone-color="#FD5D64" />
            <span style="color: #FD5D64;">{{ ((CurrentData - LastData) / LastData * 100).toFixed(2) + '%' }}</span>
        </span>
        <span v-else-if="LastData > 0 && ((CurrentData - LastData) / LastData * 100).toFixed(2) < 0">
            <span style="margin-right: 4px; color: #65D4B1;">{{ title ? '' : '环比' }}</span>
            <DownCircleTwoTone two-tone-color="#65D4B1" style="color: #65D4B1;" />
            <span style="color: #65D4B1;">{{ ((CurrentData - LastData) / LastData * 100).toFixed(2) + '%' }}</span>
        </span>
        <span v-else>
            <span style="margin-right: 4px;">{{ title ? '' : '环比' }}</span>
            -%
        </span>
    </p>
</template>

<script>
export default {
    name: 'SaasCrmchainIndex',
    props: ['CurrentData', 'LastData', 'title'],
    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style scoped>
.item {
    background-color: #ffffff;
    padding: 4px 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    width: fit-content;
}
</style>